
export enum OccupationSection {
    Leader = "Board of Directors",
    Collaborators = "Co-IR",
    HDRStudents = "Members",
    ExternalResearchers = "PhD Students"
}


export interface MemberOutputDto {
    id: string;
    name: string;
    occupation: OccupationSection;
    selected: boolean;
    profileImage?: string;
    occupationSub?: string;
    school?: string;
    university?: string;
    orderNumber: number;
    email?: string;
    linkedIn?: string;
    homepage?: string;
    github?: string;
    scholar?: string;
}


export const allMembers: MemberOutputDto[] = [
    // Leader
    {
        id: "l-1",
        selected: true,
        name: "Prof. Joaquim  \n Jorge",
        occupation: OccupationSection.Leader,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535448/jvdkcyvgrqu5foyvxpd2.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Joaquim.jpg",
        occupationSub: "Chair",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
        orderNumber: 1,
        email: "jorgej©tecnico.ulisboa.pt",
        homepage: "https://web.ist.utl.pt/jorgej/",
        scholar: "https://scholar.google.com/citations?user=RgiMdpAAAAAJ",
        linkedIn: "https://www.linkedin.com/in/jorgej",
    },
    // Collaborators
    {
        id: "c-1",
        selected: true,
        name: "Dr. Catarina  \n Moreira",
        occupation: OccupationSection.Leader,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535125/vtyukjmxpoy2afzfpzfj.png",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Catarina.png",
        occupationSub: "Co-Chair",
        school: "Human Technology Institute",
        university: "UTS",
        orderNumber: 2,
        email: "catarina.pintomoreira@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/catarinapmoreira",
        homepage: "https://cmoreira.eu",
        github: "",
        scholar: "https://scholar.google.com/citations?user=nThsEsMAAAAJ",
    },

    // HDRStudents
    /* {
        id: "3",
        selected: true,
        name: "Dr. Sandra Costa\n Sousa",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535615/v2pxj7ommlm6a4uccuhp.jpg",
        profileImage: "assets/members/Sandra-Sousa.jpeg",
        occupationSub: "",
        school: "Hospitais Privados de Portugal",
        university: "Hospital dos Lusíadas (LKC)",
        orderNumber: 1,
        email: "sandra.costa.sousa@lusiadas.pt",
        linkedIn: "https://www.linkedin.com/in/sandra-sousa-36503627/",
        scholar: "",
        homepage: "https://www.lusiadas.pt/corpo-clinico/dra-sandra-costa-sousa",
        github: "",
    }, */
    /* {
        id: "8",
        selected: true,
        name: "Yu-Liang Chou\n (Leon)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535686/lolnxfe64mt8vydthdlk.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Leon.jpg",
        occupationSub: "MPhil Student",
        orderNumber: 3,
        email: "yuliang.chou@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/leon-chou-3b56ba175",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    }, */
    /* {
        id: "9",
        selected: false,
        name: "Peng Yu\n (Kenny)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535769/cbyv9gnmzbzlc5hmoc3s.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/PengYu.jpg",
        occupationSub: "PhD Student",
        orderNumber: 4,
        email: "p6.yu@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/kenny-yu-b82373130",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "10",
        selected: true,
        name: "Bemali\n Wickramanayake",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535852/efc2p3t0h1soojoksmdi.png",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Bemali.png",
        occupationSub: "PhD Student",
        orderNumber: 5,
        email: "bemali.wickramanayake@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/bemali-wickramanayake-b2627261",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    }, */
    /* {
        id: "11",
        selected: true,
        name: "Jia Wei\n (Jenny)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1620277624/rx31uwskj5lh26vpf8ws.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Jenny.jpg",
        occupationSub: "PhD Student",
        orderNumber: 6,
        email: "jia.wei@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/jia-jenny-wei-203795132/",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    }, */
/*     {
        id: "12",
        selected: true,
        name: 'Chihcheng Hsieh\n (Richard)',
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619954466/frip22rpmow3rmas33lg.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Richard.jpg",
        occupationSub: "PhD Student",
        orderNumber: 7,
        email: "c21.hsieh@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/chihcheng-hsieh-04623989",
        scholar: "",
        homepage: "",
        github: "https://github.com/ChihchengHsieh",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "13",
        selected: true,
        name: "Zhipeng He\n (Zippo) ",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1621732433/hb4j78tkbsewdqnhdvzw.jpg",
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1652426914/20220513_071031355_iOS_e4dceo.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Zippo.jpg",
        occupationSub: "PhD Student",
        orderNumber: 8,
        email: "zhipeng.he@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/zhipenghe",
        homepage: "https://zhipenghe.me",
        github: "https://github.com/ZhipengHe",
        scholar: "",
        school: "School of Information Systems",
        university: "QUT",
    }, */
    /* {
        id: "14",
        selected: true,
        name: "Dr. Isabel Blanco \n Nobre",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633505346/avatar_dr4ypx.jpg",
        profileImage: "https://www.lusiadas.pt/sites/default/files/styles/convert_to_webp/public/practitioners/practitioner_797.jpeg.webp?itok=4KBO9DuP",
        occupationSub: "",
        orderNumber: 1,
        email: "isabel.blanco.nobre@lusiadas.pt",
        linkedIn: "https://www.linkedin.com/in/isabel-nobre-nobre-8a778740/",
        scholar: "",
        homepage: "https://www.lusiadas.pt/corpo-clinico/dra-isabel-blanco-nobre",
        github: "",
        school: "Hospitais Privados de Portugal",
        university: "Hospital dos Lusíadas (LKC)",
    },
    {
        id: "15",
        selected: true,
        name: "Dr. Anderson  \n Maciel",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633574090/GetAttachmentThumbnail_ihcpdc.jpg",
        profileImage: "https://i1.rgstatic.net/ii/profile.image/1015743185424386-1619183455024_Q512/Anderson-Maciel-2.jpg",
        occupationSub: "",
        orderNumber: 9,
        email: "anderson.maciel@tecnico.ulisboa.pt",
        linkedIn: "https://www.linkedin.com/in/anderson-maciel-8230894/",
        homepage: "https://web.tecnico.ulisboa.pt/anderson.maciel/",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon" 
    }, */
    /* {
        id: "15",
        selected: false,
        name: "Giuseppe\n Jordão",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633574090/GetAttachmentThumbnail_ihcpdc.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Giuseppe.jpg",
        occupationSub: "",
        orderNumber: 9,
        email: "guiseppe.jordao@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/giuseppe-jord%C3%A3o-5b6006120",
        homepage: "",
        github: "",
        scholar: "",
        school: "School of Information Systems",
        university: "QUT",
    }, */
    {
        id: "19",
        selected: true,
        name: "Dr. João António \n Madeiras Pereira",
        occupation: OccupationSection.Leader,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1664861923/chester_el3c5g.jpg",
        profileImage: "https://0.academia-photos.com/41037343/11147333/12439800/s200_jo_o_ant_nio.madeiras_pereira.jpg",
        occupationSub: "Research director",
        orderNumber: 10,
        homepage: "http://web.ist.utl.pt/~joao.madeiras.pereira/",
        email: "jap@inesc-id.pt",
        linkedIn: "https://www.linkedin.com/in/jo%C3%A3o-madeiras-pereira-903b514/",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon" 
        
    },
    //PHD STUDENTS
    /* {
        id: "16",
        selected: true,
        name: "Jose Neves",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633680762/GetAttachmentThumbnail_zxf6mt.jpg",
        profileImage: "assets/members/jose.png",
        orderNumber: 1,
        occupationSub: "Researcher",
        linkedIn: "https://www.linkedin.com/in/jos%C3%A9-neves-426353206/",
        homepage: "",
        github: "",
        email: "jose.s.neves@tecnico.ulisboa.pt",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "",
    },
    {
        id: "17",
        selected: true,
        name: "Joao Calem",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1634702772/andre.luis_xl4yu2.jpg",
        profileImage: "assets/members/joao.jpeg",
        orderNumber: 2,
        occupationSub: "Researcher",
        email: "joao.calem@tecnico.ulisboa.pt",
        linkedIn: "https://www.linkedin.com/in/joaocalem/",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "",
    },
    {
        id: "18",
        selected: true,
        name: "Kamal Hussain",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/c_crop,g_face,h_822,w_666/v1636075375/Diogo_wbcwq6.jpg",
        profileImage: "assets/members/kamal.jpeg",
        orderNumber: 3,
        occupationSub: "Researcher",
        email: "kamal.hussain@tecnico.ulisboa.pt",
        linkedIn: "https://www.linkedin.com/in/kamal-hussain-22b59416b/",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "",
    },
    {
        id: "20",
        selected: true,
        name: "Mona Zavichi",
        occupation: OccupationSection.ExternalResearchers,
        profileImage: "assets/members/mona.jpg",
        orderNumber: 4,
        occupationSub: "Researcher",
        email: "mona.zavichi@tecnico.ulisboa.pt",
        linkedIn: "https://www.linkedin.com/in/mona-zavichi-tork-9371bb12a/",
        homepage: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "",

    }
 */
/*     // ExternalResearchers
    {
        id: "16",
        selected: true,
        name: "Gonçalo Almeida",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633680762/GetAttachmentThumbnail_zxf6mt.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Goncalo.jpg",
        orderNumber: 2,
        occupationSub: "Master Student",
        linkedIn: "https://www.linkedin.com/in/goncalo-h-almeida",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "17",
        selected: true,
        name: "André Luís",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1634702772/andre.luis_xl4yu2.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Andre.jpg",
        orderNumber: 3,
        occupationSub: "Master Student",
        email: "andre.t.luis@tecnico.ulisboa.pt",
        linkedIn: "",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "18",
        selected: true,
        name: "Diogo Alvito",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/c_crop,g_face,h_822,w_666/v1636075375/Diogo_wbcwq6.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Diogo.jpg",
        orderNumber: 4,
        occupationSub: "Master Student",
        email: "",
        linkedIn: "https://www.linkedin.com/in/diogoalvito",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "20",
        selected: true,
        name: "Alexander Stevens",
        occupation: OccupationSection.ExternalResearchers,
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/alex.jpg",
        orderNumber: 1,
        occupationSub: "Visiting PhD Student @QUT",
        email: "alexander.stevens@kuleuven.be",
        linkedIn: "https://www.linkedin.com/in/alexander-stevens-354b41183/",
        homepage: "https://alexanderpaulstevens.github.io/portfolio/",
        scholar: "https://scholar.google.com/citations?user=fNeFT5EAAAAJ",
        school: "PhD Researcher",
        university: "KU Leuven",

    } */
];
