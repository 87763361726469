import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react'
import { BackTop, Row, Card, Col } from 'antd'
import { Link } from 'react-router-dom';
// import { LoremIpsum } from 'react-lorem-ipsum';
import { useHistory } from 'react-router';
import '../styles/Base.scss';

export default function ResearchProjectsPage() {
    const screens = useBreakpoint();

    const history = useHistory();

    return (
        <div className={`home-content`} style={{ marginLeft: screens.md ? "15%": "0%", maxWidth: screens.md ? "70%": "100%"}}>
            <BackTop />
            <div style={{ position: "absolute", top: 0, right: 0, width: "109px", height: "80px", marginRight: "20px" , marginTop: "16px"}}>
            <img src="assets/unesco.png" alt="logo" style={{ width: "100%", height: "100%" }} />
        </div>
            <div className="home-title title">
                {/* Research Projects */}Partner Institutions
            </div>
            <div className="continent-list">
                <div className="continent">
                    <h2>Africa</h2>
                    <ul>
                    <li>Institute of Equal Opportunity and Gender Equality, Cape Verde</li>
                    <li>Universidade de S. Tomé e Príncipe (St. Tomé & Príncipe University)</li>
                    <li>Universidade Katyavala Bwila (UKB), Benguela, Angola</li>
                    <li>Universidade Técnica do Atlântico, (Atlantic Technical University), Cape Verde</li>
                    </ul>
                </div>
                <div className="continent">
                    <h2>Australasia</h2>
                    <ul>
                    <li>Queensland University of Technology, Brisbane, Australia</li>
                    <li>University of Auckland, New Zealand</li>
                    <li>University of New South Wales, Sydney, Australia</li>
                    <li>University of Otago, Dunedin, New Zealand</li>
                    <li>University of Queensland, Brisbane, Australia</li>
                    <li>Victoria University Wellington, New Zealand</li>
                    <li>Charles Sturt University, Port Macquarie, NSW, Australia</li>
                    <li>Tsinghua University, Beijing, China</li>
                    </ul>
                </div>
                <div className="continent">
                    <h2>Europe</h2>
                    <ul>
                    <li>ELLIS Unit Alicante Foundation, Spain</li>
                    <li>Grupo Lusíadas, Lisboa, Portugal</li>
                    <li>IMATI - Institute of Applied Mathematics and Information Technologies, Genoa, Italy</li>
                    <li>INESC-ID, Lisboa, Portugal</li>
                    <li>Medical University Graz, Austria</li>
                    <li>Universidad de Salamanca, Spain</li>
                    <li>Universidade da Beira Interior, (University of Beira do Interior) Covilhã, Portugal</li>
                    <li>Universitat de Barcelona, Catalunya, Spain</li>
                    <li>Universitat Politècnica de Catalunya (UPC – BarcelonaTech), Spain</li>
                    <li>University College London, United Kingdom</li>
                    </ul>
                </div>
                <div className="continent">
                    <h2>North America</h2>
                    <ul>
                    <li>Carnegie-Mellon University, Pittsburgh, PA, United States of America</li>
                    <li>Columbia University in the City of New York, NY, United States of America</li>
                    <li>Dynamic Graphics Group, University of Toronto, Ontario, Canada</li>
                    <li>University of Calgary, Alberta, Canada</li>
                    <li>University of Florida, Gainesville, FL, United States of America</li>
                    <li>University of Maryland, United States of America</li>
                    <li>University of North Carolina at Chapel Hill, NC, United States of America</li>
                    <li>University of Southern California, ICT, United States of America</li>
                    <li>Iowa State University, United States of America</li>
                    </ul>
                </div>
                <div className="continent">
                    <h2>South America</h2>
                    <ul>
                    <li>Federal University of Rio Grande do Sul, Porto Alegre, RS, Brazil</li>
                    <li>Pontifícia Universidade Católica (Catholic University) do Rio de Janeiro, Brazil</li>
                    </ul>
                </div>
                </div>


            {/* <div>
                <Row gutter={[32, 32]} justify="center" typeof="flex">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}  xxl={6}>
                        <Card className="card" hoverable
                            onClick={() => {
                                history.push('/Explainable-Predictive-Process-Analytics')
                                // TODO: Push to research theme page.
                            }}
                            cover={<img 
                                alt="explainble_predictive_process_analytics" 
                                src={`${process.env.PUBLIC_URL}assets/project/predictive-process-analytics-img.png`} />}
                        >
                            <Card.Meta title={<div className="card-title" >{<Link className="link" 
                                to={"/Explainable-Predictive-Process-Analytics"}>Explainable Predictive Process Analytics</Link>}</div>} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                        <Card className="card" 
                            hoverable
                            onClick={() => {
                                history.push('/Causal-Models')
                                // TODO: Push to research theme page. 
                            }}
                            cover={<img 
                                    alt="Probabilistic & Causal Models for Responsible AI IMG" 
                                    src={`${process.env.PUBLIC_URL}assets/project/responsible-ai-img.png`} />}
                        >
                            <Card.Meta 
                                title={<div className="card-title">{<Link className="link" to="/Causal-Models">Probabilistic & Causal Models for Responsible AI</Link>}</div>} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                        <Card className="card" hoverable
                            onClick={() => {
                                history.push('/Persuasive-Models')
                                // TODO: Push to research theme page. 
                            }}
                            cover={<img 
                                alt="exlainble_predictive_process_analytics" 
                                src={`${process.env.PUBLIC_URL}assets/project/persuasive-models-img.png`} />}
                        >
                            <Card.Meta title={<div className="card-title">{<Link className="link" to="/Persuasive-Models">Persuasive Models for Explainable AI</Link>}</div>} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                        <Card className="card" hoverable
                            onClick={() => {
                                history.push('/Explainable-Medical')
                                // TODO: Push to research theme page. 
                                // testing
                            }}
                            cover={<img 
                                alt="exlainble_predictive_process_analytics" 
                                src={`${process.env.PUBLIC_URL}assets/project/medical-ai-img.png`} />}
                        >
                            <Card.Meta title={<div className="card-title">{<Link className="link" to="/Explainable-Medical">Explainable Medical Diagnostic Systems</Link>}</div>} />
                        </Card>
                    </Col>
                </Row>
            </div> */}
        </div >
    )
}
