import { Divider, BackTop } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { LoremIpsum } from 'react-lorem-ipsum';
import React from 'react'
import '../styles/Base.scss'

export default function NewsEvents() {
    const screen = useBreakpoint();

    return (
        <div className={`home-content`} style={{ marginLeft: screen.md ? "15%": "0%", maxWidth: screen.md ? "70%": "100%"}}/* style={{ marginLeft: screen.md ? "15%": "0%", maxWidth: screen.md ? "70%": "100%"}} */>
            <BackTop />
            <div style={{ position: "absolute", top: 0, right: 0, width: "109px", height: "80px", marginRight: "20px" , marginTop: "16px"}}>
            <img src="assets/unesco.png" alt="logo" style={{ width: "100%", height: "100%" }} />
        </div>
  <div className="home-title">
    <div className="title">
        News and Events
    </div>
    <div className="paragraph">
        <table style={{ border: "none" }}>
            <tbody>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Sep. 2022</td>
                    <td>UNESCO Chair Approved and Signed by the Rector of the University of Lisboa</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Nov. 2022</td>
                    <td>Joint project by IST, UNcg, Clemson and Duke Universities Started</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Jan. 2023</td>
                    <td>Tsinghua University Joined the AI&VR Consortium</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Feb. 2023</td>
                    <td>Xavier Project started with funding from the Portuguese Science Foundation</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Mar. 2023</td>
                    <td>Workshop on XR 4 Health co-located with IEEE VR 2023</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>July 2023</td>
                    <td>Presentation at the Summerschool on AI&XR, Matera, Italy</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Aug. 2023</td>
                    <td>W Huerst and Joaquim Jorge organize a workshop on AI&VR at SIGGRAPH 2023</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Nov. 2023</td>
                    <td>Catarina Moreira and Joaquim Jorge present a Course on Challenges and Opportunities for VR in Healthcare at SIGGRAPH Asia 2023 in Sydney</td>
                </tr>
                <tr>
                    <td style={{ width: "20%" }}>Dec. 19th, 2023</td>
                    <td>AI and Extended Reality - Transforming Education Towards 2025, <a href='https://canal.uned.es/video/6582a0d4371fa0276d4f4c34?track_id=6582a169371fa02776694552'>Vision and actions of the UNESCO Chairs</a>.</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>Jan. 2024</td>
                    <td>Catarina Moreira and Anderson Maciel participate in the organization of AIxVR 2024 in Los Angeles (Hybrid)</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>July 15th, 2024</td>
                    <td>Signed MoU between UNESCO Chairs on AI&XR and Digital Anatomy (France)</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>July 16th, 2024</td>
                    <td>Data Science Institute at UTS Sydney Joined the AI&VR Consortium</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td style={{ width: "20%" }}>July 19th, 2024</td>
                    <td>Talk about distortions of perception at the Winter School on the Psychology of XR at the University of South Australia in Adelaide.</td>
                </tr>
                <tr>
                    <td style={{ fontWeight: 'bold'}}>Upcoming:</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td>Sep. 13th, 2024</td>
                    <td>Session at the United Nations Science Summit</td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td>Jan. 2025</td>
                    <td><a href='https://www.dagstuhl.de/25031'>Dagstuhl Seminar Addressing Future Challenges of Telemedicine Applications</a></td>
                </tr>
                <tr style={{ borderBottom: "10px solid transparent" }}>
                    <td>Jan. 2025</td>
                    <td>Organizing the AIxVR Symposium in Tecnico Lisboa</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

            
            
            <div className="home-title">
            

                <div className="paragraph">
                    
                    {/* <ul>
                        <li>Create breakthroughs in building explainable analytics for machine intelligence</li>
                            <ul>
                                <li>Devise new methods and techniques to build robust and interpretable models underpinned by machine intelligence</li>
                                <li>Design new theories and algorithms to detect biases and provide ethical and fair analyses of machine learned predictions</li>
                                <li>Develop new frameworks and tools for user-centric intelligent predictive systems that incorporate human-in-the-loop mechanisms</li>
                            </ul>
                        <li>Apply explainable machine intelligence to a wide range of application domains and industry sectors to promote innovative and sustainable industrialization</li>
                        <li>Build collaborative networks and foster multi-stakeholder partnerships in the context of explainable machine intelligence</li>
                        <li>Cultivate young research students to become new generations of career excellence achievers in Data Science</li>
                    </ul> */}
                </div>
            </div>

            {/* <Divider /> */}
            {/* <div className="home-title">
                <div className="title">
                    Our Values
                </div>

                <div className="paragraph">
                <ul>


                    <li>
                        Pursuit of Excellence
                    </li>

                    <li>
                        Creativity and Critical Thinking
                    </li>

                    <li>
                        Diversity and Inclusiveness
                    </li>
                    <li>
                        Positivity and Passion
                    </li>
                </ul>
                </div>
            </div>

            <Divider /> */}
            {/* <div className="home-title">
                <div className="title">
                Acknowledgement
                </div>

                <div className="paragraph">
                <ul>
                    <li>
                    QUT Women in Research Grant Scheme 2021 
                    </li>

                    <li>
                    QUT Center for Data Science First Byte Funding Program 2021 
                    </li>

                    <li>
                    Australian Government Research Training Program (International) Scholarship 2019 – 2022 
                    </li>

                    <li>
                    Australian Government Research Training Program (International) Scholarship 2020 – 2023 
                    </li>

                    <li>
                    Australian Government Research Training Program (Domestic) Scholarship 2020 – 2023 
                    </li>

                    <li>
                    QUT Science and Engineering Faculty Diversity Scholarship 2020 – 2023  
                    </li>

                    <li>
                    QUT Postgraduate Research Award (International) Scholarship 2021 – 2024
                    </li>

                    <li>
                    QUT Science and Engineering Faculty Scholarship 2021 – 2024 
                    </li>

                    <li>
                    QUT Centre for Data Science Higher Degree Research Scholarship 2021 – 2024
                    </li>

                    <li>
                    QUT Center for Data Science Top Up Scholarship 2021 – 2024
                    </li>

                    <li>
                    QUT Vacation Research Experience Scheme Scholarship 2020
                    </li>

                    <li>
                    QUT International Merit Scholarship 2021 
                    </li>

                    <li>
                    Higher Degree Research Internship Industry Scholarship 2021 
                    </li>
                </ul>
                </div>
            </div> */}

        </div >
    )
}
